//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBdzd4YtlpymE6LBNqk0MDIHwBDRgv1OS4",
  authDomain: "instant-tap.firebaseapp.com",
  projectId: "instant-tap",
  storageBucket: "instant-tap.appspot.com",
  messagingSenderId: "144999113283",
  appId: "1:144999113283:web:4c1143f46ce9b31492eaf6",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
